<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  >
    <!-- <template v-slot:authCode>
      <div style="position: relative">
          <el-input  v-model="formData.authCode" :placeholder="$t('adminAccount.codeText')" maxlength="6" >
          <el-button slot="suffix" size="mini"  @click.stop="sendcode" v-if="codeShow">{{$t('adminAccount.getCode')}}<span id="count"></span>
          </el-button>
          <el-button slot="suffix" size="mini" disabled v-if="!codeShow">{{$t('adminAccount.sendCode')}}{{count}}s</el-button>
        </el-input>
      </div>
    </template> -->
  </dialog-form-list>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    // const self = this
    return {
      formData: {
        accountId: this.tableRow.accountId,
        // authCodeSeqNo:''
      },
      // smscode:'',
      // count: '', // 验证码倒计时
      // timer:null ,
      // codeShow: true,
      config: [
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: true,
          },
        },
        // lang:手机号
        // {
        //   label: this.$t('adminAccount.mobile'),
        //   prop: 'mobile',
        //   rules: {
        //     noNull: true,
        //     type: 'mobile',
        //   },
        //   // hidden: true,
        //   tagProps: {
        //     maxLength: 11,
        //   },
        //   tagEvents:{
        //     change(data){
        //       const reg = /^[1][3-9][0-9]{9}$/
        //       if (reg.test(data)) {
        //         self.config[2].hidden = false
        //       }else{
        //         self.config[2].hidden = true
        //       }
        //     }, 
        //   }
        // },
        // 获取手机验证吗
        // {
        //   tag: 'slot-content',
        //   label: this.$t('adminAccount.code'),
        //   prop: 'authCode',
        //   rules: {
        //     noNull: true,
        //   },
        //   hidden: true,
        //   tagProps: {
        //     placeholder: this.$t('base.validateCode'),
        //   },  
        // },
        // lang:新密码
        {
          label: this.$t('personalInfo.password'),
          prop: 'password',
          rules: {
            noNull: true,
          },
          tagProps: {
            showPassword: true,
          },
        },
      ],
    }
  },
  mounted() {
    // this.getMobile()
  },
  methods: {
    // getMobile() {
    //   const mobile = this.tableRow.mobile
    //   setTimeout(() => {
    //     this.formData.mobile = mobile
    //   }, 200);
    //   const reg = /^[1][3-9][0-9]{9}$/
    //   if (reg.test(mobile)) {
    //      this.config[1].hidden = false
    //   }else{
    //      this.config[1].hidden = true
    //   }
    // }, 
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountResetPassword,
        data: {
          id: this.tableRow.id,
          password: this.formData.password,
        },
      }).then(() => {
        // lang:重置成功
        this.$element.showMsg(this.$t('base.resetSuccess'), 'success')
        this.$emit('closePage', 'resetPassword')
      })
    },
    // 获取验证码
    // sendcode() {
    //    this.$ajax({
    //     url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sendCode,
    //     data:{
    //       mobile: this.formData.mobile,
    //     }
    //   }).then((data) => {
    //     this.formData.authCodeSeqNo = data
    //     console.log(data)
    //   })

    //   const TIME_COUNT = 30 //倒计时60秒
    //   if(!this.timer) {
    //       this.count = TIME_COUNT
    //       this.codeShow = false
    //       this.timer = setInterval(() => {
    //         if(this.count > 0 && this.count <= TIME_COUNT) {
    //           this.count--
    //         } else {
    //           this.codeShow = true
    //           clearInterval(this.timer)
    //           this.timer = null
    //         }
    //       }, 1000)
    //    }
    // }
  },
}
</script>